import { HashRouter as Router, Route, Routes } from "react-router-dom";
import { LinearProgress } from "@mui/material";
import React, { lazy, Suspense } from "react";
import "./App.css";

const LazyMainPage = lazy(() => import("./pages/main/MainPage"));
const LazyAboutUs = lazy(() => import("./pages/aboutUs/AboutUs"));
const LazyServices = lazy(() => import("./pages/services/Services"));
const LazyContact = lazy(() => import("./pages/contact/Contact"));

function App() {
  return (
    <Router>
      <div className="App">
        <Suspense fallback={<LinearProgress color="success" />}>
          <Routes>
            <Route path="/" element={<LazyMainPage />} />
            <Route path="/hakkimda" element={<LazyAboutUs />} />
            <Route path="/hizmetler" element={<LazyServices />} />
            <Route path="/iletisim" element={<LazyContact />} />
          </Routes>
        </Suspense>
      </div>
    </Router>
  );
}

export default App;
